<template>
	<div class="container-modal form-modal large">
		<div class="container-holder">
			<div class="info-content">
				<div class="header-modal">
					<div class="icon"><icon-cart /></div>
					<div class="title" v-html="$t('register.invoice_data')"></div>
				</div>
				<div class="container-content-modal">
					<div class="payment-template">
						<div class="payment-form">
							<template v-if="user.need_instance">
								<!-- <div class="title-section">{{ $t('register.invoice_data') }}</div> -->
								<div class="form-section-register">
									<div class="input-login-group-tbf w-mob-100">
										<div class="input-box">
											<div class="icon-right-new"><icon-dots /></div>
											<multiselect
											class="select-tags-tbf"
											v-model="selectedTypePerson"
											:options="['company', 'individual']" 
											:show-labels="false"
											:allow-empty="false"
											:searchable="false"
											@input="changeTypePerson">
												<template slot="singleLabel" slot-scope="props">
													<span class="option__title">{{ $t('register_options.' + props.option) }}</span>
												</template>
												<template slot="option" slot-scope="props">
													<span class="option__title">{{ $t('register_options.' + props.option) }}</span>
												</template>
											</multiselect>
										</div>
									</div>

									<div class="input-login-group-tbf w-mob-100 w-tbf-100" v-bind:class="{has_error: $v.company.complet_name.$error}" v-if="selectedTypePerson == 'individual'">
										<div class="input-box">
											<div class="icon-right-new"><icon-people /></div>
											<input type="text" :placeholder="$t('register.complet_name')" class="input-text" v-model="company.complet_name" ref="first_input">
										</div>
									</div>

									<div class="input-login-group-tbf w-mob-100 w-tbf-100" v-bind:class="{has_error: $v.company.cnp.$error}" v-if="selectedTypePerson == 'individual'">
										<div class="input-box">
											<div class="icon-right-new"><icon-cnp /></div>
											<input type="text" :placeholder="$t('register.cnp')" class="input-text" v-model="company.cnp">
										</div>
									</div>

									<div class="input-login-group-tbf w-mob-100 w-tbf-100" v-bind:class="{has_error: $v.company.name.$error}" v-if="selectedTypePerson == 'company'">
										<div class="input-box">
											<div class="icon-right-new"><icon-building /></div>
											<input type="text" :placeholder="$t('register.company')" class="input-text" v-model="company.name">
										</div>
									</div>

									<div class="input-login-group-tbf w-mob-100 w-tbf-100" v-bind:class="{has_error: $v.company.cui.$error}" v-if="selectedTypePerson == 'company'">
										<div class="input-box">
											<div class="icon-right-new"><icon-cui /></div>
											<input type="text" :placeholder="$t('register.cuiplaceholder')" class="input-text" v-model="company.cui">
										</div>
										<div class="error-msg" v-if="invalidCui">{{ $t('validator.cui_invalid') }}</div>
									</div>
									<div class="input-login-group-tbf w-mob-100 w-tbf-100" v-if="!user.freePackages && selectPackageInterval == 'year'" >
										<div class="input-box">
											<div class="icon-right-new"><icon-dots /></div>
											<multiselect
											class="select-tags-tbf"
											v-model="typePayment"
											:options="['online', 'op']" 
											:show-labels="false"
											:allow-empty="false"
											:searchable="false">
												<template slot="singleLabel" slot-scope="props">
													<span class="option__title">{{ $t('register_options.' + props.option) }}</span>
												</template>
												<template slot="option" slot-scope="props">
													<span class="option__title">{{ $t('register_options.' + props.option) }}</span>
												</template>
											</multiselect>
										</div>
									</div>
								</div>
							</template>

							<template v-if="!user.freePackages && typePayment == 'online'">
								<div class="title-section">{{ $t('register.card_data') }}</div>
								<div class="form-section-register">
									<div class="input-login-group-tbf w-mob-100" v-bind:class="{has_error: $v.card.card_number.$error || stripeError != '' || cardErrors.cardNumber}" id="payment">
										<div class="input-box">
											<div class="icon-right-new"><icon-card /></div>
											<input type="tel" :placeholder="$t('register.card_number_placeholder')" class="input-text" v-model="card.card_number" v-cardformat:formatCardNumber maxlength="19">
										</div>
										<div class="error-msg" v-if="cardErrors.cardNumber">{{ cardErrors.cardNumber }}</div>
										<div class="error-msg" v-else-if="stripeError != ''">{{ stripeError }}</div>
									</div>

									<div class="input-login-group-tbf w-mob-100 w-tbf-100" v-bind:class="{has_error: $v.card.card_expiration.$error || cardErrors.cardExpiry}">
										<div class="input-box">
											<div class="icon-right-new"><icon-date /></div>
											<input type="tel" :placeholder="$t('register.validity_date_placeholder')" class="input-text" v-model="card.card_expiration" v-cardformat:formatCardExpiry maxlength="9">
										</div>
										<div class="error-msg" v-if="cardErrors.cardExpiry">{{ cardErrors.cardExpiry }}</div>
									</div>

									<div class="input-login-group-tbf w-mob-100 w-tbf-100" v-bind:class="{has_error: $v.card.card_cvc.$error || cardErrors.cardCvc}">
										<div class="input-box">
											<div class="icon-right-new"><icon-password /></div>
											<input type="tel" :placeholder="$t('register.cvc_placeholder')" class="input-text" v-model="card.card_cvc" v-cardformat:formatCardCVC maxlength="4">
										</div>
										<div class="error-msg" v-if="cardErrors.cardCvc">{{ cardErrors.cardCvc }}</div>
									</div>
								</div>
							</template>

							<div class="submit-form payment">
								<button id="buttonNextStep" class="button-submit" @click="goNextStep">
									<div class="loader-spin" v-if="loadingSubmit"></div>
									<div class="text" v-else>{{ user.freePackages ? $t('register.btn-simple-register') : (typePayment == 'online' ? $t('register.btn-pay') : $t('register.button-op-submit')) }}</div>
								</button>

								<div v-if="onPayment && !user.freePackages" class="change-plan" v-html="$t('register.check_3d_security')"></div>
								<div v-if="succededPayment != '' && !user.freePackages" class="change-plan alert-tbf red" >{{succededPayment}}</div>

								<div class="powered-by">
									<a href="https://stripe.com" target="_blank">
										<img src="/build/images/powered_by_stripe.png">
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import IconClose from '../Icons/Close'
	import IconPeople from "../Icons/People"
	import IconCnp from '../Icons/Cnp'
	import IconBuilding	from '../Icons/Building'
	import IconCui	from '../Icons/Cui'
	import IconDate from '../Icons/Date'
	import IconCard from '../Icons/Card'
	import IconPassword from '../Icons/Password'
	import IconCart from '../Icons/Cart'
	import IconDots from '../Icons/Dots'

	import { required, minLength, requiredIf } from 'vuelidate/lib/validators'

	export default {
		data(){
			return {
				selectedTypePerson: 'company',
				hasCompany: 1,
				invalidCui: false,
				card:{
					card_number: '',
					card_expiration: '',
					card_cvc: ''
				},
				cardErrors: {},
				stripeError: '',
				company: {
					name: '',
					complet_name: '',
					cui: '',
					formatedCui: '',
					cnp: ''
				},
				typePayment: 'online',
				selectPackageInterval: 'month',
				onPayment: false,
				succededPayment: '',
				loadingSubmit: false
			}
		},
		watch: {
			'company.name': function(val) {
				this.company.name = val.charAt(0).toUpperCase() + val.substring(1);
			},
			'company.complet_name': function(val) {
				this.company.complet_name = val.charAt(0).toUpperCase() + val.substring(1);
			}
		},
		props:{
			user: Object
		},
		components: {
			IconClose,
			IconPeople,
			IconBuilding,
			IconCnp,
			IconCui,
			IconDate,
			IconCard,
			IconPassword,
			IconCart,
			IconDots
		},
		validations: {
			selectedTypePerson: {required},
			company: {	
				name: { required: requiredIf(function() { return this.user.need_instance && this.hasCompany === 1 })},
				cui: {required: requiredIf(function() { return this.user.need_instance && this.hasCompany === 1 })},
				complet_name: {required: requiredIf(function() { return this.user.need_instance && this.hasCompany === 0 })},
				cnp: {required: requiredIf(function() { return this.user.need_instance && this.hasCompany === 0 })},
			},
			card: {
				card_number: { required: requiredIf(function() { return !this.user.freePackages && this.typePayment == 'online' }) },
				card_expiration: { required: requiredIf(function() { return !this.user.freePackages && this.typePayment == 'online' }) },
				card_cvc: { required: requiredIf(function() { return !this.user.freePackages && this.typePayment == 'online' }) }
			}
		},
		async mounted() {
			this.selectPackageInterval = this.user.packages.selectedPackage.interval;
			this.company.complet_name = `${this.$auth.user().first_name} ${this.$auth.user().last_name}`
			this.company.name = this.$auth.user().company_name ? this.$auth.user().company_name : ''
		},
		methods: {
			goNextStep(){
				this.invalidCui = false

				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName)

				btnSubmit.disabled = true
				this.loadingSubmit = true

				this.$v.$touch()

				this.cardErrors = {}
				this.stripeError = ''
				var cardError = false

				if(!this.user.freePackages && this.typePayment == 'online') {
					// validate card number
					if(!this.$cardFormat.validateCardNumber(this.card.card_number)){
						this.cardErrors.cardNumber = this.$t('validator.invalid_credit_card');
						cardError = true
					};

					// validate card expiry
					if (!this.$cardFormat.validateCardExpiry(this.card.card_expiration)) {
						this.cardErrors.cardExpiry = this.$t('validator.invalid_credit_card_date');
						cardError = true
					};

					// validate card CVC
					if (!this.$cardFormat.validateCardCVC(this.card.card_cvc)) {
						this.cardErrors.cardCvc = this.$t('validator.invalid_credit_card_cvc');
						cardError = true
					};
				}

				if(this.$v.$invalid || cardError){
					btnSubmit.disabled = false
					this.loadingSubmit = false
				}else{
					if(this.user.need_instance){
						if(this.hasCompany){
							this.checkVatNumber()
						}else{
							this.submitRegister()
						}
					}else{
						this.submitPayment()
					}
				}
			},
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			submitRegister(with_meta_cui = false){
				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName);

				var formData = {
					email: this.$auth.user().email,
					country: this.$auth.user().country ? this.$auth.user().country : 'RO',
					has_company: this.hasCompany
				}

				if(this.hasCompany){
					formData.company_name = this.company.name;
					formData.cui = this.company.formatedCui;
				}else{
					formData.name = this.company.complet_name;
					formData.cnp = this.company.cnp;
				}

				formData.package_id = this.user.packages.selectedPackage.id;
			
				axios.post('auth/sync-instance', formData)
				.then(() => {

					var checkOrgInstance = setInterval(() => {
						axios.get(`${process.env.VUE_APP_ORGANIGRAM_LINK}/api/check-instance`)
						.then(({data}) => {
							if(data.data.has_instance) { 
								clearInterval(checkOrgInstance);
								this.submitPayment(with_meta_cui)
							}
						})
					}, 2000);

					// setTimeout(() => {
					// 	// this.submitPayment(with_meta_cui)
					// }, 3000)
				})
				.catch(error => {
					btnSubmit.disabled = false
					this.loadingSubmit = false
				})
			},
			submitPayment(with_meta_cui = false){
				if(this.typePayment == 'online'){
					// Plata cu cardul
					this.onPayment = true

					var buttonName = 'buttonNextStep'
					var btnSubmit = document.getElementById(buttonName)

					let syncPaymentData = {
						number: this.card.card_number,
						exp_month: this.card.card_expiration.substring(0, 2),
						exp_year: this.card.card_expiration.substring(5, 9),
						cvc: this.card.card_cvc,
						coupon: this.user.coupon,
						package_id: this.user.packages.selectedPackage.id
					}

					if(this.$cookies.get('optionsRegisterUser').discount) {
						syncPaymentData.reducere = this.$cookies.get('optionsRegisterUser').discount
					}

					if(with_meta_cui){
						syncPaymentData.meta_cui = true
					}
					axios.post('/sync-global-payment', syncPaymentData)
					.then(({data}) => {

						if(data.status == 'error'){
							var responseObj = data.data;

							if(responseObj.subscription && responseObj.subscription.latest_invoice.payment_intent.status == 'requires_source_action'){
								this.checkForScaActivated( responseObj );
							}else{
								this.onPayment = false
								this.stripeError = responseObj.message;

								btnSubmit.disabled = false
								this.loadingSubmit = false

								if(this.stripeError == null){
									this.$root.$emit('open_modal', 'error_payment')
								}
							}
						}else{
							this.$router.push({name: 'register-success'})
						}
					})
					.catch(error => {
						this.onPayment = false
						btnSubmit.disabled = false
						this.loadingSubmit = false
					})
				}else{
					// Plata prin OP
					this.$emit("next_step", {user: this.user, next_step: 4});
				}
			},
			changeTypePerson(type) {
				this.selectedTypePerson = type;
				if(this.selectedTypePerson == 'company'){
					this.hasCompany = 1;
				}else{
					this.hasCompany = 0;
				}
			},
			checkVatNumber(){
				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName)

				var cui_number = this.company.cui.replace(/[^0-9]/g,'')
				var cui_country = this.company.cui.replace(/[^a-zA-Z]+/g, '').toUpperCase()

				if(!cui_country.length){
					cui_country = 'RO'
				}
				
				this.company.formatedCui = `${cui_country}${cui_number}`
				setTimeout(() => this.submitRegister(), 100)
			},
			checkForScaActivated( responseStripe ){
				var _this = this;

				if(responseStripe.subscription && ['requires_source_action', 'requires_action'].includes(responseStripe.subscription.latest_invoice.payment_intent.status) ){
					var sub = responseStripe.subscription;

					let stripe = Stripe(responseStripe.publishable_key);
					stripe
					  .confirmCardPayment(sub.latest_invoice.payment_intent.client_secret)
					  .then(function(result) {
						var buttonName = 'buttonNextStep'
						var btnSubmit = document.getElementById(buttonName)

					  	if(result.error){
							_this.loadingSubmit = false;
							_this.stripeError = result.error.message;
							_this.onPayment = false
							btnSubmit.disabled = false
					  	}else{
							_this.succededPayment = "Plata pentru TBF Digital s-a realizat cu succes! În câteva momente veți fi direcționat către aplicația TBF Digital."

							axios.post('/sync-mark-as-active')
							.then(({data}) => {
								btnSubmit.disabled = false;
								_this.$auth.fetch();
								_this.$router.push('/register-success');
							})
					  	}
					  });
				}else{
					this.stripeError = error.response.message;
					this.loadingSubmit = false;
					this.onPayment = false
					btnSubmit.disabled = false
				}
			}
		}
	}
</script>

<style lang="scss">
	.payment-template{
		display: flex;
		align-items: flex-start;
		@media (max-width: 780px) {
			flex-direction: column;
			.summary-checkout {
				max-width: initial;
				width: 100%;
				flex: 0 0 100%;
			}
		}
		.payment-form{
			padding-left: 30px;
			@media (max-width: 780px) {
				padding-left: 0;
				margin-top: 30px;
			}
			.title-section{
				font-weight: 500;
				font-size: 20px;
				color: $primary;
				margin-bottom: 20px;
			}
		}
	}
</style>